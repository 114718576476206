<!-- 
	This is the sign in page, it uses the dashboard layout in: 
	"./layouts/Default.vue" .
 -->

 <template>
	<div class="sign-in bg-gray-3">
		
		<a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" style="margin-top: 80px;">

			<a-col :span="24" :md="10" :lg="10" :xl="{span: 8, offset: 0}" class="col-form pt-5 mt-5">
				
				<a-card :bordered="false" class="card-info px-3 mx-0 pt-20">
					<a-row :gutter="[24,24]">

						<a-button type="text" class="text-primary ml-15 mb-20" v-if="current > 0" @click="previousScreen"><a-icon type="arrow-left" theme="outlined" /> &nbsp;&nbsp; Back  </a-button>
						
						<a-col :span="24" :md="24" :lg="24" class="px-25" v-if="current == 0">
							<a-row class="text-center">
								<a-col :lg="24">
									<!-- <img src="../../public/images/icon.png" alt="" width="80px" height="80px" style="border-radius: 20px;">  -->
								</a-col>
							</a-row>
							<h3 class="mb-0 mt-3 text-center">Account Username!</h3>
							<p class="text-muted text-center mt-1 pt-0 mb-4">Enter your account username (phone number).</p>

							<a-alert
								class="mb-3"
								v-if="errorUsername.show"
								:message="errorUsername.message"
								type="error"
								closable
								:after-close="handleClose"
								show-icon 
							/>

							<a-form
								id="components-form-demo-normal-login"
								:form="formUsername"
								class="login-form"
								@submit="handleSubmitUsername"
								:hideRequiredMark="true">

								<a-form-item class="mb-2" label="Username(Phone number)" :colon="false">
                                    <a-input 
                                        v-decorator="[
                                        'username',
                                        { rules: [{ required: true, message: 'Please enter your phone number!' }] },
                                        ]" placeholder="Eg: 0621314682" />
                                </a-form-item>

								<a-form-item class="mt-4">
									<a-button :loading="isLoadingUsername" type="primary" block html-type="submit" class="login-form-button mt-10 text-white" >
										SUBMIT
									</a-button>
								</a-form-item>


								<!-- <a-row :gutters="24" class="pt-10 pb-10">
									<a-col :span="24" class="text-center pb-5">
										Already have an account? <a class="" style="text-decoration: none !important; color: #632163;" :href="`/sign-up`">
											<strong>Sign in</strong>
										</a>
									</a-col>
								</a-row> -->

							</a-form>
						</a-col>


						<a-col :span="24" :md="24" :lg="24" class="px-25" v-if="current == 1">
							<a-row class="text-center">
								<a-col :lg="24">
									<!-- <img src="../../public/images/icon.png" alt="" width="80px" height="80px" style="border-radius: 20px;">  -->
								</a-col>
							</a-row>
							<h3 class="mb-0 mt-3 text-center">Verify Account!</h3>
							<p class="text-muted text-center mt-1 pt-0 mb-4">A token has been sent to your phone number you submitted in previous step.</p>

							<a-alert
								class="mb-3"
								v-if="error.show"
								:message="error.message"
								type="error"
								closable
								:after-close="handleClose"
								show-icon 
							/>

							<a-alert
                                class="mx-25"
								v-if="success.show"
								:message="success.message"
								type="success"
								closable
								:after-close="handleClose"
								show-icon 
							/>

							<a-form
								id="components-form-demo-normal-login"
								:form="form"
								class="login-form"
								@submit="handleSubmit"
								:hideRequiredMark="true">

								<a-form-item class="mb-3" label="Token" :colon="false">
									<a-input
									v-decorator="[
									'token',
									{ rules: [{ required: true, message: 'Please input token!' }] },
									]" type="text" placeholder="Token" />
								</a-form-item>

								<a-form-item class="mt-4">
									<a-button type="primary" block html-type="submit" class="login-form-button mt-10 text-white">
										VERIRY
									</a-button>
								</a-form-item>


								<a-row :gutters="24" class="pt-0">
									<a-col :span="24" class="text-center pb-5">
										Didn't receive the token?&nbsp; <a-button type="text" :loading="loadingToken"  class="ml-2 pl-0 text-primary" style="font-size: 14px; text-decoration: none !important; border: none;" @click="resendToken">
											<strong>Resend</strong>
										</a-button>
									</a-col>
								</a-row>

								<!-- <a-row :gutters="24" class="pt-10 pb-10">
									<a-col :span="24" class="text-center pb-5">
										Already have an account? <a class="" style="text-decoration: none !important; color: #632163;" :href="`/sign-up`">
											<strong>Sign in</strong>
										</a>
									</a-col>
								</a-row> -->

							</a-form>
						</a-col>

						<a-col :span="24" :md="24" :lg="24" class="px-25" v-if="current == 2">
							<a-row class="text-center">
								<a-col :lg="24">
									<!-- <img src="../../public/images/icon.png" alt="" width="80px" height="80px" style="border-radius: 20px;">  -->
								</a-col>
							</a-row>
							<h3 class="mb-0 mt-3 text-center">Reset Password!</h3>
							<p class="text-muted text-center mt-1 pt-0 mb-4">Enter your new password (8 must be 8 characters long).</p>

							<a-alert
								class="mb-3"
								v-if="errorPassword.show"
								:message="errorPassword.message"
								type="error"
								closable
								:after-close="handleClose"
								show-icon 
							/>

							<a-form
								id="components-form-demo-normal-login"
								:form="formPassword"
								class="login-form"
								@submit="handleSubmitPassword"
								:hideRequiredMark="true">

								<a-form-item class="py-0 my-0 mb-4" label="Password" :colon="false">
									<a-input
									v-decorator="[
									'password',
									{ rules: [{ required: true, message: 'Please enter your password!' }] },
									]" type="password" placeholder="Password" />
								</a-form-item>

								<a-form-item class="py-0 my-0" label="Confirm Password" :colon="false">
									<a-input
									v-decorator="[
									'passwordConfirmation',
									{ rules: [{ required: true, message: 'Please enter your confirmation password!' }] },
									]" type="password" placeholder="Confirm Password" />
								</a-form-item>
								
								<a-form-item class="mt-4">
									<a-button :loading="isLoadingUsername" type="primary" block html-type="submit" class="login-form-button mt-10 text-white" >
										RESET
									</a-button>
								</a-form-item>


								<!-- <a-row :gutters="24" class="pt-10 pb-10">
									<a-col :span="24" class="text-center pb-5">
										Already have an account? <a class="" style="text-decoration: none !important; color: #632163;" :href="`/sign-up`">
											<strong>Sign in</strong>
										</a>
									</a-col>
								</a-row> -->

							</a-form>
						</a-col>


					</a-row>
				</a-card>
			</a-col>


		</a-row>
		
	</div>
</template>

<script>
	
	export default ({
		

		data() {
			return {
				// Binded model property for "Sign In Form" switch button for "Remember Me" .
				
				success: {
					message: null,
					show: false,
				},

				countryCode: '255',

				isLoading: false,
				isLoadingUsername: false,
				isLoadingPassword: false,

				loadingToken: false,

				current: 0,
				steps: [
					{
						title: 'Username',
						content: 'First-content',
					},
					{
						title: 'Verification Code',
						content: 'Second-content',
					},
					{
						title: 'New Password',
						content: 'Last-content',
					},
				],

				error: {
					message: null,
					show: false,
				},

				errorUsername: {
					message: null,
					show: false,
				},

				username: null,

				errorPassword: {
					message: null,
					show: false,
				},

				resetPassword: {
					token: null,
					password: null,
					passwordConfirmation: null,
				},


				notify: {
					text: null,
					type: null,
				},
			}
		},
		beforeCreate() {
			// Creates the form and adds to it component's "form" property.
			this.formUsername = this.$form.createForm(this, { name: 'username_login' });
			this.form = this.$form.createForm(this, { name: 'normal_login' });
			this.formPassword = this.$form.createForm(this, { name: 'password_login' });
		},
		created() {
		},
		methods: {

			onSelectCountry({dialCode}) {
				this.countryCode = dialCode;
			},

			handleClose() {
				this.error.show = false;
			},

			next() {
				this.current++;
			},

			previousScreen() {
				if(this.current > 0) {
					this.current--;
				}
			},


			// Handles input validation after submission.
			handleSubmitUsername(e) {
				e.preventDefault();
				this.formUsername.validateFields(async(err, values) => {
					if ( !err ) {
						this.errorUsername.show = false;
						this.errorUsername.message = null;
						this.isLoadingUsername = true;

						let username = '';

						const usernameCopy = values.username;
						
						if(usernameCopy.substring(0, 4).includes(this.countryCode) && !usernameCopy.includes("+")) {
							username = "+" + usernameCopy;
						}

						else if(usernameCopy.substring(0, 4).includes(this.countryCode) && usernameCopy.includes("+")) {
							username =  usernameCopy;
						}

						else if(usernameCopy[0] == "0" || usernameCopy[0] == 0) {
							username = "+" + this.countryCode + "" + usernameCopy.substring(1)
						}

						else if(usernameCopy[0] != "0" || usernameCopy[0] != 0) {
							username = "+" + this.countryCode + "" + usernameCopy
						}

						else {
							username = usernameCopy;
						}

						let url = `${this.$BACKEND_URL}/auth/forgot-password`;
						
						this.$AXIOS.post(url, { username }).then(async(response) => {
							if (response.status >= 200 && response.status < 210) {

								this.username = username;

								this.next()
							}

							this.isLoadingUsername = false
						}).catch((error) => {
							this.isLoadingUsername = false;
							
							this.errorUsername.message = error.response != null  && error.response.data != null ? error.response.data.message : "Connection error";
							this.errorUsername.show = true
						});
					}
				});
			},

			async resendToken() {

				this.loadingToken = true;

				let url = `${this.$BACKEND_URL}/auth/forgot-password`;
				
				this.$AXIOS.post(url, { username: this.username }).then(async(response) => {
					
					if (response.status >= 200 && response.status < 210) {

						this.success.message = `Token has been sent to ${this.username} successfully`
						this.success.show = true;

						this.loadingToken = false;
					
					}

					// this.isLoading = false
				}).catch((error) => {
					// eslint-disable-next-line no-console
					console.error(error)
					this.loadingToken = false;
					
					this.error.message = error.response != null  && error.response.data != null ? error.response.data.message : "Connection error";
					this.error.show = true
				});
			},

			// Handles input validation after submission.
			handleSubmit(e) {
				e.preventDefault();
				this.form.validateFields(async(err, values) => {
					if ( !err ) {
						
						const token = values.token;

						this.resetPassword.token = token;

						this.next()

					}
				});
			},


			handleSubmitPassword(e) {
				e.preventDefault();
				this.formPassword.validateFields(async(err, values) => {
					if ( !err ) {
						this.errorPassword.show = false;
						this.errorPassword.message = null;
						this.isLoadingUsername = true;

						const payload = values;
						
						payload.token = this.resetPassword.token;

						let url = `${this.$BACKEND_URL}/auth/reset-password`;
						
						this.$AXIOS.post(url, payload).then(async(response) => {
							if (response.status >= 200 && response.status < 210) {

								this.notify.text = "Password reset was successfully"
								this.notify.type = "success"

								this.$notify(this.notify)
								

								this.$router.push(`/`);
							}

							this.isLoadingUsername = false
						}).catch((error) => {
							this.isLoadingUsername = false;
							
							this.errorPassword.message = error.response != null  && error.response.data != null ? error.response.data.message : "Connection error";
							this.errorPassword.show = true
						});
					}
				});
			},

		},
		
	})

</script>

<style lang="scss" scoped>
	body {
		background-color: #ffffff;
	}
	#signinBtn, #signinBtn:hover {
		background-color: #632163 !important;
		color: white !important;
	}
</style>
<!-- 
	This is the sign in page, it uses the dashboard layout in: 
	"./layouts/Default.vue" .
 -->
