var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sign-in bg-gray-3"},[_c('a-row',{staticStyle:{"margin-top":"80px"},attrs:{"type":"flex","gutter":[24,24],"justify":"space-around","align":"middle"}},[_c('a-col',{staticClass:"col-form pt-5 mt-5",attrs:{"span":24,"md":10,"lg":10,"xl":{span: 8, offset: 0}}},[_c('a-card',{staticClass:"card-info px-3 mx-0 pt-20",attrs:{"bordered":false}},[_c('a-row',{attrs:{"gutter":[24,24]}},[(_vm.current > 0)?_c('a-button',{staticClass:"text-primary ml-15 mb-20",attrs:{"type":"text"},on:{"click":_vm.previousScreen}},[_c('a-icon',{attrs:{"type":"arrow-left","theme":"outlined"}}),_vm._v("    Back ")],1):_vm._e(),(_vm.current == 0)?_c('a-col',{staticClass:"px-25",attrs:{"span":24,"md":24,"lg":24}},[_c('a-row',{staticClass:"text-center"},[_c('a-col',{attrs:{"lg":24}})],1),_c('h3',{staticClass:"mb-0 mt-3 text-center"},[_vm._v("Account Username!")]),_c('p',{staticClass:"text-muted text-center mt-1 pt-0 mb-4"},[_vm._v("Enter your account username (phone number).")]),(_vm.errorUsername.show)?_c('a-alert',{staticClass:"mb-3",attrs:{"message":_vm.errorUsername.message,"type":"error","closable":"","after-close":_vm.handleClose,"show-icon":""}}):_vm._e(),_c('a-form',{staticClass:"login-form",attrs:{"id":"components-form-demo-normal-login","form":_vm.formUsername,"hideRequiredMark":true},on:{"submit":_vm.handleSubmitUsername}},[_c('a-form-item',{staticClass:"mb-2",attrs:{"label":"Username(Phone number)","colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                                        'username',
                                        { rules: [{ required: true, message: 'Please enter your phone number!' }] },
                                        ]),expression:"[\n                                        'username',\n                                        { rules: [{ required: true, message: 'Please enter your phone number!' }] },\n                                        ]"}],attrs:{"placeholder":"Eg: 0621314682"}})],1),_c('a-form-item',{staticClass:"mt-4"},[_c('a-button',{staticClass:"login-form-button mt-10 text-white",attrs:{"loading":_vm.isLoadingUsername,"type":"primary","block":"","html-type":"submit"}},[_vm._v(" SUBMIT ")])],1)],1)],1):_vm._e(),(_vm.current == 1)?_c('a-col',{staticClass:"px-25",attrs:{"span":24,"md":24,"lg":24}},[_c('a-row',{staticClass:"text-center"},[_c('a-col',{attrs:{"lg":24}})],1),_c('h3',{staticClass:"mb-0 mt-3 text-center"},[_vm._v("Verify Account!")]),_c('p',{staticClass:"text-muted text-center mt-1 pt-0 mb-4"},[_vm._v("A token has been sent to your phone number you submitted in previous step.")]),(_vm.error.show)?_c('a-alert',{staticClass:"mb-3",attrs:{"message":_vm.error.message,"type":"error","closable":"","after-close":_vm.handleClose,"show-icon":""}}):_vm._e(),(_vm.success.show)?_c('a-alert',{staticClass:"mx-25",attrs:{"message":_vm.success.message,"type":"success","closable":"","after-close":_vm.handleClose,"show-icon":""}}):_vm._e(),_c('a-form',{staticClass:"login-form",attrs:{"id":"components-form-demo-normal-login","form":_vm.form,"hideRequiredMark":true},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{staticClass:"mb-3",attrs:{"label":"Token","colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
									'token',
									{ rules: [{ required: true, message: 'Please input token!' }] },
									]),expression:"[\n\t\t\t\t\t\t\t\t\t'token',\n\t\t\t\t\t\t\t\t\t{ rules: [{ required: true, message: 'Please input token!' }] },\n\t\t\t\t\t\t\t\t\t]"}],attrs:{"type":"text","placeholder":"Token"}})],1),_c('a-form-item',{staticClass:"mt-4"},[_c('a-button',{staticClass:"login-form-button mt-10 text-white",attrs:{"type":"primary","block":"","html-type":"submit"}},[_vm._v(" VERIRY ")])],1),_c('a-row',{staticClass:"pt-0",attrs:{"gutters":24}},[_c('a-col',{staticClass:"text-center pb-5",attrs:{"span":24}},[_vm._v(" Didn't receive the token?  "),_c('a-button',{staticClass:"ml-2 pl-0 text-primary",staticStyle:{"font-size":"14px","text-decoration":"none !important","border":"none"},attrs:{"type":"text","loading":_vm.loadingToken},on:{"click":_vm.resendToken}},[_c('strong',[_vm._v("Resend")])])],1)],1)],1)],1):_vm._e(),(_vm.current == 2)?_c('a-col',{staticClass:"px-25",attrs:{"span":24,"md":24,"lg":24}},[_c('a-row',{staticClass:"text-center"},[_c('a-col',{attrs:{"lg":24}})],1),_c('h3',{staticClass:"mb-0 mt-3 text-center"},[_vm._v("Reset Password!")]),_c('p',{staticClass:"text-muted text-center mt-1 pt-0 mb-4"},[_vm._v("Enter your new password (8 must be 8 characters long).")]),(_vm.errorPassword.show)?_c('a-alert',{staticClass:"mb-3",attrs:{"message":_vm.errorPassword.message,"type":"error","closable":"","after-close":_vm.handleClose,"show-icon":""}}):_vm._e(),_c('a-form',{staticClass:"login-form",attrs:{"id":"components-form-demo-normal-login","form":_vm.formPassword,"hideRequiredMark":true},on:{"submit":_vm.handleSubmitPassword}},[_c('a-form-item',{staticClass:"py-0 my-0 mb-4",attrs:{"label":"Password","colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
									'password',
									{ rules: [{ required: true, message: 'Please enter your password!' }] },
									]),expression:"[\n\t\t\t\t\t\t\t\t\t'password',\n\t\t\t\t\t\t\t\t\t{ rules: [{ required: true, message: 'Please enter your password!' }] },\n\t\t\t\t\t\t\t\t\t]"}],attrs:{"type":"password","placeholder":"Password"}})],1),_c('a-form-item',{staticClass:"py-0 my-0",attrs:{"label":"Confirm Password","colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
									'passwordConfirmation',
									{ rules: [{ required: true, message: 'Please enter your confirmation password!' }] },
									]),expression:"[\n\t\t\t\t\t\t\t\t\t'passwordConfirmation',\n\t\t\t\t\t\t\t\t\t{ rules: [{ required: true, message: 'Please enter your confirmation password!' }] },\n\t\t\t\t\t\t\t\t\t]"}],attrs:{"type":"password","placeholder":"Confirm Password"}})],1),_c('a-form-item',{staticClass:"mt-4"},[_c('a-button',{staticClass:"login-form-button mt-10 text-white",attrs:{"loading":_vm.isLoadingUsername,"type":"primary","block":"","html-type":"submit"}},[_vm._v(" RESET ")])],1)],1)],1):_vm._e()],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }